import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/replacing",
    name: "replacing",

    component: () => import("../views/replacing/index.vue"),
  },
  {
    path: "/pk",
    name: "pk",
    component: () => import("../views/pkHome/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Home/common/login.vue"),
  },
  {
    path: "/roll",
    name: "roll",
    component: () => import("../views/rollHome/index.vue"),
  },
  {
    path: "/shopping",
    name: "shopping",
    component: () => import("../views/shopping/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Home/common/login.vue"),
  },
  {
    path: "/knapsack",
    name: "knapsack",
    component: () => import("../views/Home/common/knapsack.vue"),
  },
  {
    path: "/recharge",
    name: "recharge",
    component: () => import("../views/Home/common/recharge.vue"),
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("../views/Home/common/notifications.vue"),
  },
  {
    path: "/personal",
    name: "personal",
    component: () => import("../views/Home/common/personal.vue"),
  },
  {
    path: "/help",
    name: "help",
    component: () => import("../views/Home/common/help.vue"),
  },
  {
    path: "/account",
    name: "account",
    component: () => import("../views/Home/common/account.vue"),
  },
  {
    path: "/task",
    name: "task",
    component: () => import("../views/task/index.vue"),
  },
  {
    path: "/welfare",
    name: "welfare",
    component: () => import("../views/welfare/index.vue"),
  },
  {
    path: "/open",
    name: "open",
    component: () => import("../views/openbox/index.vue"),
    meta:{
      iskeep:true
    }
  },
  {
    path: "/openbox",
    name: "openbox",
    component: () => import("../views/openbox/common/openbox.vue"),
  },
  {
    path: "/dream",
    name: "dream",
    component: () => import("../views/dream/index.vue"),
  },
  {
    path: "/hongbao",
    name: "hongbao",
    component: () => import("../views/hongbao/hongbao.vue"),
  },
  {
    path: "/downloadApp",
    name: "downloadApp",
    component: () => import("../views/downloadApp/downloadApp.vue"),
  },
  {
    path: "/home",
    name: "home2",
    component: () => import("../views/Home/index.vue"),
  },
  {
    path: "/battleroom",
    name: "battleroom",
    component: () => import("../views/pkHome/battleroom.vue"),
  },
  {
    path: "/creatroom",
    name: "creatroom",
    component: () => import("../views/pkHome/creatroom.vue"),
  },
  {
    path: "/roomDetail",
    name: "roomDetail",
    component: () => import("../views/pkHome/roomDetail.vue"),
  },
  {
    path: "/battleroom",
    name: "battleroom",
    component: () => import("../views/pkHome/battleroom.vue"),
  },
  {
    // 测试页面
    path: "/lottery",
    name: "lottery",
    component: () => import("../views/pkHome/test01.vue"),
  },
  {
    // 测试页面
    path: "/lotterySeat",
    name: "lotterySeat",
    component: () => import("../views/pkHome/test02.vue"),
  },
  {
    path: "/rolldetial",
    name: "rolldetial",
    component: () => import("../views/rollHome/common/rolldetial.vue"),
  },
  {
    path: "/index2",
    name: "ceshi",
    component: () => import("../views/rollHome/index2.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
