import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/vuex/store";
import "@/api/scoket";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/font/iconfont.css";
import "animate.css";

import "@/assets/style/success.scss";
import money from "@/components/common/money.vue";
import integral from "@/components/common/integral.vue";
import boxicon from "@/components/common/boxicon.vue";
import handleback from "@/components/common/back.vue";
import plusreduce from "@/components/common/plusreduce.vue";
import illustrate from "@/components/illustrate.vue";
// import vueSeamlessScroll from "vue-seamless-scroll";
import { socket } from "socket.io";
import VueAgile from 'vue-agile'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import toastRegistry from './views/toast/index'
Vue.prototype.$video = Video
// Vue.use(vueSeamlessScroll);

Vue.use(ElementUI);
Vue.prototype.$socket = socket
Vue.component("money", money);
Vue.component("integral", integral);
Vue.component("handleback", handleback);
Vue.component("plusreduce", plusreduce);
Vue.component("boxicon", boxicon);
Vue.component("illustrate", illustrate);
Vue.config.productionTip = false;
Vue.use(VueAgile)
Vue.use(toastRegistry)
import Router from "vue-router";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.filter('fullPath', (path) => {
  if (typeof path === 'string' && path.trim()) {
    const baseUrl = 'http://47.239.104.160:8080';
    return baseUrl + path.trim();
  }
  return ''; // 返回空字符串或默认值
});
new Vue({
  router,
  store,
  beforeCreate() {
    // Vue.prototype.$ws=ws
    Vue.prototype.$bus = this;
  },
  render: (h) => h(App),
}).$mount("#app");
